<template>
<div>
  <HeaderView :page_index="0"></HeaderView>
  <el-dialog v-model="show.login" width="30%"  center :show-close="false" @closed="close_view"><LoginDialog /></el-dialog>
  <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left; margin: 30px 30px 30px 30px;" />
  <div v-else class="home">
    <el-container>
      <el-container>
        <el-aside width="200px">
          <!-- <div v-for="(type, index) in type_list" :key="index"  :class="index===current_type_index?'tab_select tab':'tab'" @click="click_tab(index)">
            <p>{{type.name}}</p>
            <p>{{type.num}}</p>
          </div> -->
          <ul  id="powers">

            <li v-if="power_first.name">
              <div class="heng2 border_bottom" style="height: 50px;" @click="click_tab_name(power_first.name)"><span :class="[power_first.indexPower === current_type_index ? ' power_select' : '']">{{ power_first.name }}</span> <span class="num">{{ power_first.num }}人</span></div>
            </li>
            <li >
              <!-- <div :class="'heng2 border_bottom' + 0 === current_type_index ?' power_select': ''" style="height: 50px;" @click="click_tab_name('全部')"><span :class="[power_dic['全部'].indexPower === current_type_index ?'  power_select': ''">全部</span> <span class="num">{{ power_dic['全部'].num }}人</span></div> -->
              <div class="border_bottom" style="height: 50px;" @click="click_tab_name('全部')"><span :class="[power_dic['全部'].indexPower  === current_type_index ? ' power_select': '' ]">全部</span> <span class="num">{{ power_dic['全部'].num }}人</span></div>
            </li>
            <!-- <li >
              <div class="heng2 border_bottom" style="height: 50px;" @click="click_tab_name('上古')"><span :class="[power_dic['东周'].indexPower === current_type_index ? ' power_select' : '']">上古</span> <span class="num">{{ power_dic['上古'].num }}人</span></div>
            </li> -->
            <li >
              <div class="left">
                <div class="border_right" style="height: 100px;" @click="click_tab_name('东周')"><span :class="[power_dic['东周'].indexPower === current_type_index ? ' power_select' : '']">东周</span> <span class="num">{{ power_dic['东周'].num }}人</span></div>
                <!-- <div class="border_right border_bottom" style="height: 100px;" @click="click_tab_name('东周')"><span class="power_select">东周</span> <span class="num">{{ power_dic['东周'].num }}人</span></div> -->
                <!-- <div class="" style="height: 20px;" @click="click_tab_name('战国')">x</div> -->
              </div>
              <div class="right">
                <div class="border_bottom" style="height: 50px;" @click="click_tab_name('春秋')"><span :class="[power_dic['春秋'].indexPower === current_type_index ? ' power_select' : '']">春秋</span> <span class="num">{{ power_dic['春秋'].num }}人</span></div>
                <div class="" style="height: 50px;" @click="click_tab_name('战国')"><span :class="[power_dic['战国'].indexPower === current_type_index ? ' power_select' : '']">战国</span> <span class="num">{{ power_dic['战国'].num }}人</span></div>
              </div>
            </li>
            <li >
              <div class="heng2 border_top" style="height: 25px;" @click="click_tab_name('秦朝')"><span :class="[power_dic['秦朝'].indexPower === current_type_index ? ' power_select' : '']">秦朝</span> <span class="num">{{ power_dic['秦朝'].num }}人</span></div>
            </li>
            <li >
              <div class="heng2 border_top" style="height: 80px;" @click="click_tab_name('西汉')"><span :class="[power_dic['西汉'].indexPower === current_type_index ? ' power_select' : '']">西汉</span> <span class="num">{{ power_dic['西汉'].num }}人</span></div>
            </li>
            <li >
              <div class="heng2 border_top" style="height: 25px;" @click="click_tab_name('新朝')"><span :class="[power_dic['新朝'].indexPower === current_type_index ? ' power_select' : '']">新朝</span> <span class="num">{{ power_dic['新朝'].num }}人</span></div>
            </li>
            <li >
              <div class="heng2 border_top" style="height: 70px;" @click="click_tab_name('东汉')"><span :class="[power_dic['东汉'].indexPower === current_type_index ? ' power_select' : '']">东汉</span> <span class="num">{{ power_dic['东汉'].num }}人</span></div>
            </li>
            <li>
              <div>
                <div class="heng2 border_top" style="height: 40px;" @click="click_tab_name('三国')"><span :class="[power_dic['三国'].indexPower === current_type_index ? ' power_select' : '']">三国</span> <span class="num">{{ power_dic['三国'].num }}人</span></div>
              </div>
              <div class="left">
                <div class="border_bottom border_top border_right" style="height: 30px;" @click="click_tab_name('西晋')"><span :class="[power_dic['西晋'].indexPower === current_type_index ? ' power_select' : '']">西晋</span> <span class="num">{{ power_dic['西晋'].num }}人</span></div>
                <div class="border_bottom border_right" style="height: 40px;" @click="click_tab_name('东晋')"><span :class="[power_dic['东晋'].indexPower === current_type_index ? ' power_select' : '']">东晋</span> <span class="num">{{ power_dic['东晋'].num }}人</span></div>
                <!-- <div class="border_right" style="height: 9px;" @click="click_tab_name('南北朝')">x</div> -->
                <div class="border_right" style="height: 9px;" @click="click_tab_name('南北朝')"></div>
              </div>
              <div class="right">
                <!-- <div class="" style="height: 10px;" @click="click_tab_name('三国')">x</div> -->
                <div class="" style="height: 10px;" @click="click_tab_name('三国')"></div>
                <div class=" border_top border_bottom" style="height: 70px;" @click="click_tab_name('十六国')"><span :class="[power_dic['十六国'].indexPower === current_type_index ? ' power_select' : '']">十六国</span> <span class="num">{{ power_dic['十六国'].num }}人</span></div>
              </div>
              <div>
                <div class="heng2 border_bottom" style="height: 60px;" @click="click_tab_name('南北朝')"><span :class="[power_dic['南北朝'].indexPower === current_type_index ? ' power_select' : '']">南北朝</span> <span class="num">{{ power_dic['南北朝'].num }}人</span></div>
              </div>
            </li>

            <li >
              <div class="heng2" style="height: 30px;" @click="click_tab_name('隋朝')"><span :class="[power_dic['隋朝'].indexPower === current_type_index ? ' power_select' : '']">隋朝</span> <span class="num">{{ power_dic['隋朝'].num }}人</span></div>
            </li>
            <li >
              <div class="heng2 border_top border_bottom" style="height: 80px;" @click="click_tab_name('唐朝')"><span :class="[power_dic['唐朝'].indexPower === current_type_index ? ' power_select' : '']">唐朝</span> <span class="num">{{ power_dic['唐朝'].num }}人</span></div>
            </li>
            <li>
              <div class="left">
                <div class="border_bottom border_right" style="height: 30px;" @click="click_tab_name('五代十国')"><span :class="[power_dic['五代十国'].indexPower === current_type_index ? ' power_select' : '']">五代十国</span> <span class="num">{{ power_dic['五代十国'].num }}人</span></div>
                <div class="border_bottom border_right" style="height: 50px;" @click="click_tab_name('北宋')"><span :class="[power_dic['北宋'].indexPower === current_type_index ? ' power_select' : '']">北宋</span> <span class="num">{{ power_dic['北宋'].num }}人</span></div>
                <div class="border_bottom border_right" style="height: 50px;" @click="click_tab_name('南宋')"><span :class="[power_dic['南宋'].indexPower === current_type_index ? ' power_select' : '']">南宋</span> <span class="num">{{ power_dic['南宋'].num }}人</span></div>
              </div>
              <div class="right">
                <div class="border_bottom" style="height: 75px;" @click="click_tab_name('辽')"><span :class="[power_dic['辽'].indexPower === current_type_index ? ' power_select' : '']">辽</span> <span class="num">{{ power_dic['辽'].num }}人</span></div>
                <div class="border_bottom" style="height: 40px;" @click="click_tab_name('金')"><span :class="[power_dic['金'].indexPower === current_type_index ? ' power_select' : '']">金</span> <span class="num">{{ power_dic['金'].num }}人</span></div>
                <div class="" style="height: 15px;" @click="click_tab_name('蒙古')"><span :class="[power_dic['蒙古'].indexPower === current_type_index ? ' power_select' : '']">蒙古</span> <span class="num">{{ power_dic['蒙古'].num }}人</span></div>
              </div>
            </li>
            <li >
              <div class="heng2 " style="height: 75px;" @click="click_tab_name('元朝')"><span :class="[power_dic['元朝'].indexPower === current_type_index ? ' power_select' : '']">元朝</span> <span class="num">{{ power_dic['元朝'].num }}人</span></div>
              <div class="left">
                <div class="border_bottom" style="height: 15px;" @click="click_tab_name('元朝')">北元</div>
              </div>
              <div class="right">
                <!-- <div class="border_top border_left" style="height: 15px;" @click="click_tab_name('明朝')">x</div> -->
                <div class="border_top border_left" style="height: 15px;" @click="click_tab_name('明朝')"></div>
              </div>
            </li>
            <li >
              <div class="heng2 " style="height: 80px;" @click="click_tab_name('明朝')"><span :class="[power_dic['明朝'].indexPower === current_type_index ? ' power_select' : '']">明朝</span> <span class="num">{{ power_dic['明朝'].num }}人</span></div>
              <div class="left">
                <div class="border_bottom" style="height: 15px;" @click="click_tab_name('明朝')">南明</div>
              </div>
              <div class="right">
                <div class="border_top border_left" style="height: 15px;" @click="click_tab_name('清朝')">后金</div>
              </div>
            </li>
            <li >
              <div class="heng2" style="height: 80px;" @click="click_tab_name('清朝')"><span :class="[power_dic['清朝'].indexPower === current_type_index ? ' power_select' : '']">清朝</span> <span class="num">{{ power_dic['清朝'].num }}人</span></div>
            </li>
          </ul>
        </el-aside>
        <el-container style="text-align: left; padding-left: 20px;">
          <el-skeleton v-if="loading3" :rows="5" animated  style="margin: 30px 30px 0px 20px;"/>
          <el-main  v-else>
            <div class="tab2" style="text-align: left;">
              <el-tag  v-for="(item, index)  in group_list" :key="index" @click="click_quality_tab(index)" style="margin: 6px 6px 6px 0px; cursor:pointer;" type="info" :effect="index === current_group_index?'dark':'plain'">{{item.name}} {{item.num}}</el-tag>
              <div style="display:inline-block;margin:0px 10px" >
                <div style="display:inline-block;"><el-input v-model="this.year_range[0]" style="width:90px" type="number" placeholder="开始年" /></div>
                <span style="color: #aaa;"> - </span>
                <div style="display:inline-block;"><el-input v-model="this.year_range[1]" style="width:90px" type="number" placeholder="结束年" /></div>
              </div>
              <el-dropdown :split-button='true' size='small'>
                <span class="el-dropdown-link" >排序<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item  v-for="(paixu, index) in paixus" :key="index" ><span @click="set_paixu(paixu[1])" :class="person_paixu===paixu[1] ? 'green' : ''" >按{{paixu[0]}}</span></el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <el-button class="set"  style="display:inline-block;margin:0px 10px"  plain type="primary" size=""  @click="confirm_to_fresh()" >刷新</el-button>

            </div>
            <el-skeleton v-if="loading2" :rows="5" animated  style="text-align: left;" />
            <div v-else>
              <div v-if="currentYear!=9999" class="checkbox"  style="text-align:left; margin: 0px 50px 20px 0px; ">
                <div style="font-size: 16px; vertical-align: middle; display: inline-block; margin: 0px 6px 0px 0px; ">公元 </div>
                <div style="font-weight: bold; font-size: 32px; vertical-align: middle; display: inline-block;">{{ currentYear }}年 </div>
                <div  v-if="year_range[0]!=year_range[1]" style="vertical-align: middle; display: inline-block; width: calc(100% - 200px);  margin: 0px 0px 0px 30px;"><el-slider :show-tooltip="false" v-model="currentYear" :min="year_range[0]" :max="year_range[1]" /></div>
              </div>
              <!-- <div v-if="list.length" style="text-align: right; margin:20px">
                <el-button class="set" type="warning" size="" @click="change_zicis('state', 1)" plain>一键 state to 1</el-button>
                <el-button class="set" type="success" size=""  @click="change_zicis('in_my', 1)" plain>一键本页全部in_my</el-button>
                <el-button class="set" type="warning" size="" @click="change_zicis('fix', 1)" plain>一键本页全部fix</el-button>
                <el-button class="set" type="danger" size="" @click="change_zicis('state', 0)" plain>一键本页全部not</el-button>
              </div> -->
              <OnePersonCanPull :person_info="info" :current_year="currentYear" tag="middle" :action="'goTo'" v-for="(info, index) in list" :key="index"  />
              <!-- <div v-if="currentYear!=9999">
                <OnePersonTime :person_info="info" :current_year="currentYear" tag="middle" :action="'goTo'" v-for="(info, index) in list" :key="index"  />
              </div>
              <div v-else>
                <OnePersonCanPull :person_info="info" :current_year="currentYear" tag="middle" :action="'goTo'" v-for="(info, index) in list" :key="index"  />
              </div> -->
              <div class="footer">
                <el-pagination v-if="group_list[current_group_index].page!==0" @current-change="get_list" :current-page="current_page"  layout="prev, pager, next" :page-count="group_list[current_group_index].page"></el-pagination>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
    <FooterView show_what="all" />
  </div>
</div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import OnePersonCanPull from '@/components/items/OnePersonCanPull'
import HeaderView from '@/components/other/HeaderView'
import FooterView from '@/components/other/FooterView'
import LoginDialog from '@/components/other/LoginDialog'
// import router from '@/router/index.js'

export default {
  name: 'Person_index',
  mounted () {
    this.get_index()
  },
  components: {
    OnePersonCanPull, FooterView, HeaderView, LoginDialog
  },
  data () {
    return {
      loading: true,
      loading2: false,
      loading3: false,
      is_long: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      type_list: [],
      group_list: [],
      list: [],
      paixus: [['默认', 'num'], ['出生', 'year_start'], ['寿长', 'year_long'], ['寿长(倒序)', 'year_long_reverse']],
      // in_wheres: [['不介意', null], ['结巴', 'j'], ['pku', 'p'], ['上版本', '1'], ['都介意', 'all']],
      group_tags: [['minzu', 'minzu'], ['power', 'power'], ['net', 'net'], ['my', 'my']],
      in_mys: [['不介意', null], ['yes', 1], ['not', 0], ['未标', '-']],
      see_wants: [['全部', 'all'], ['type', 'type'], ['my', 'my'], ['net', 'net'], ['都不', 'none']],
      year_births: [['不介意', null], ['有', 1], ['无', 0]],
      year_deaths: [['不介意', null], ['有', 1], ['无', 0]],
      fixs: [['不介意', null], ['yes', 1], ['not', 0], ['未标', '-']],
      directions: [['不限制', '-'], ['大于', 'greater'], ['小于', 'less']],
      person_states: [['不介意', null], ['保留', 1], ['删除', 0]],
      current_type_index: 0,
      current_group_index: 0,
      current_page: 1,
      person_paixu: 'num',
      // in_where: null,
      // not_in_where: null
      in_my: null,
      fix: null,
      year_birth: null,
      year_death: null,
      group_tag: 'my',
      limit_index: ['-', 0],
      limit_num: ['-', 0],
      person_state: 1,
      see_want: 'net',
      power_dic: {},
      power_first: {},
      show: {},
      year_range: [null, null],
      currentYear: 9999,
      powerId: ''

    }
  },
  methods: {
    get_index () {
      if (localStorage.getItem('person_paixu')) {
        this.person_paixu = localStorage.getItem('person_paixu')
      }
      if (this.year_range[0] === null) {
        const query = this.$route.query
        this.powerId = query.power
      }
      // console.log(query)
      this.list = []
      this.type_list = []

      axios.post(Utils.check_is_niming() ? '/person_index_unlogin/' : '/person_index/', { paixu: this.person_paixu, group_tag: this.group_tag, power_id: this.powerId, year_range: this.year_range })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          // this.$router.replace({ query: {} })

          this.list = res.data.data.list
          this.type_list = res.data.data.type_list
          this.group_list = res.data.data.group_list
          this.loading = false
          var powerDic = {}
          // console.log(22222, this.in_where)
          for (var i = 0; i < this.type_list.length; i++) {
            var power = this.type_list[i]
            power.indexPower = i
            // console.log(this.type_list[i].value, powerId)
            powerDic[this.type_list[i].name] = power
            if (this.powerId && this.type_list[i].id === this.powerId) {
              this.current_type_index = i
            }
          }
          this.power_dic = powerDic
          // console.log(powerDic)
          if (['全部', '上古', '东周', '春秋', '战国', '秦朝', '西汉', '新朝', '东汉', '三国', '西晋', '东晋', '十六国', '南北朝', '隋朝', '唐朝', '五代十国', '北宋', '南宋', '辽', '金', '蒙古', '元朝', '明朝', '清朝'].indexOf(this.type_list[0].name) === -1) {
            this.power_first = this.type_list[0]
            console.log(this.power_first)
          }
        })
    },
    click_tab_name (name) {
      console.log(name)
      console.log(this.power_dic[name])
      this.click_tab(this.power_dic[name].indexPower)
    },
    get_list  (e) {
      if (this.year_range[0] === '' || this.year_range[0] === null) {
        this.year_range[0] = null
      } else {
        this.year_range[0] = parseInt(this.year_range[0], 10)
      }
      if (this.year_range[1] === '' || this.year_range[1] === null) {
        this.year_range[1] = null
      } else {
        this.year_range[1] = parseInt(this.year_range[1], 10)
      }
      if (this.year_range[0] == null && this.year_range[1] == null) {
        this.currentYear = 9999
      } else {
        if (this.year_range[0] != null &&
            this.year_range[1] != null &&
            this.year_range[0] > this.year_range[1]) {
          Utils.alert('时间区间：开始年应小于等于结束年')
          return
        }
        if (this.year_range[0] == null && this.year_range[1] != null) {
          this.year_range[0] = this.year_range[1]
        }
        if (this.year_range[0] != null && this.year_range[1] == null) {
          this.year_range[1] = this.year_range[0]
        }
        if (this.year_range[0] != null && this.year_range[0] > 2000) {
          Utils.alert('时间区间：开始年应小于1912年')
          return
        }
        if (this.year_range[1] != null && this.year_range[1] > 1912) {
          Utils.alert('时间区间结束年应小于1912年')
          return
        }
        if (this.year_range[0] != null) {
          this.currentYear = this.year_range[0]
        } else {
          this.currentYear = 9999
        }
        if (this.currentYear !== 9999 && Utils.check_is_niming()) {
          this.show.login = true
          return
        }
      }
      this.current_page = e
      if (e > 1 && Utils.check_is_niming()) {
        this.show.login = true
        return
      }
      this.list = []
      this.loading2 = true

      axios.post(Utils.check_is_niming() ? '/person_list_unlogin/' : '/person_list/', { type: this.type_list[this.current_type_index].value, group_tag: this.group_tag, group: this.group_list[this.current_group_index].value, page: e, paixu: this.person_paixu, year_range: this.year_range })
        .then(res => {
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.list = res.data.data.list
          window.scrollTo(0, 0)
        })
    },
    click_tab (e) {
      if (this.current_type_index !== e) {
        this.current_type_index = e
        this.current_page = 1
        this.year_range = [null, null]
        this.currentYear = 9999
        this.refresh_quality_tab_list()
      }
    },
    click_quality_tab (e) {
      if (this.current_group_index !== e) {
        this.current_group_index = e
        this.current_page = 1
        if (this.group_list[this.current_group_index].num === 0) {
          this.list = []
          return
        }
        this.loading2 = true
        this.get_list(1)
      }
    },
    open_sw (e) {
      window.open(store.state.base_url + e, '_blank')
    },
    refresh_quality_tab_list () {
      if (this.year_range[0] === '' || this.year_range[0] === null) {
        this.year_range[0] = null
      } else {
        this.year_range[0] = parseInt(this.year_range[0], 10)
      }
      if (this.year_range[1] === '' || this.year_range[1] === null) {
        this.year_range[1] = null
      } else {
        this.year_range[1] = parseInt(this.year_range[1], 10)
      }
      if (this.year_range[0] == null && this.year_range[1] == null) {
        this.currentYear = 9999
      } else {
        if (this.year_range[0] != null &&
            this.year_range[1] != null &&
            this.year_range[0] > this.year_range[1]) {
          Utils.alert('时间区间：开始年应小于等于结束年')
          return
        }
        if (this.year_range[0] == null && this.year_range[1] != null) {
          this.year_range[0] = this.year_range[1]
        }
        if (this.year_range[0] != null && this.year_range[1] == null) {
          this.year_range[1] = this.year_range[0]
        }
        if (this.year_range[0] != null && this.year_range[0] > 2000) {
          Utils.alert('时间区间：开始年应小于1912年')
          return
        }
        if (this.year_range[1] != null && this.year_range[1] > 1912) {
          Utils.alert('时间区间结束年应小于1912年')
          return
        }
        if (this.year_range[0] != null) {
          this.currentYear = this.year_range[0]
        } else {
          this.currentYear = 9999
        }
        if (this.currentYear !== 9999 && Utils.check_is_niming()) {
          this.show.login = true
          return
        }
      }
      this.current_page = 1
      this.loading3 = true
      axios.post(Utils.check_is_niming() ? '/person_group_list_unlogin/' : '/person_group_list/', { type: this.type_list[this.current_type_index].value, paixu: this.person_paixu, group_tag: this.group_tag, year_range: this.year_range })
        .then(res => {
          this.loading3 = false
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.group_list = res.data.data.group_list
          this.current_group_index = 0
          this.list = res.data.data.list
        })
    },
    set_paixu (e) {
      if (Utils.check_is_niming()) {
        this.show.login = true
        return
      }
      if (e !== 'num' && !store.state.is_vip) {
        Utils.alert('非默认排序会员可用，请打开历史年轮APP成为会员')
        return
      }
      localStorage.setItem('person_paixu', e)
      this.person_paixu = e
      // this.refresh_quality_tab_list()
    },
    confirm_to_fresh () {
      if (this.year_range[0] === '' || this.year_range[0] === null) {
        this.year_range[0] = null
      } else {
        this.year_range[0] = parseInt(this.year_range[0], 10)
      }
      if (this.year_range[1] === '' || this.year_range[1] === null) {
        this.year_range[1] = null
      } else {
        this.year_range[1] = parseInt(this.year_range[1], 10)
      }
      if (this.year_range[0] == null && this.year_range[1] == null) {
        this.currentYear = 9999
      } else {
        if (this.year_range[0] != null &&
            this.year_range[1] != null &&
            this.year_range[0] > this.year_range[1]) {
          Utils.alert('时间区间：开始年应小于等于结束年')
          return
        }
        if (this.year_range[0] == null && this.year_range[1] != null) {
          this.year_range[0] = this.year_range[1]
        }
        if (this.year_range[0] != null && this.year_range[1] == null) {
          this.year_range[1] = this.year_range[0]
        }
        if (this.year_range[0] != null && this.year_range[0] > 2000) {
          Utils.alert('时间区间：开始年应小于1912年')
          return
        }
        if (this.year_range[1] != null && this.year_range[1] > 1912) {
          Utils.alert('时间区间结束年应小于1912年')
          return
        }
        if (this.year_range[0] != null) {
          this.currentYear = this.year_range[0]
        } else {
          this.currentYear = 9999
        }
        if (this.currentYear !== 9999 && Utils.check_is_niming()) {
          this.show.login = true
          return
        }
      }

      this.list = []
      this.type_list = []
      this.group_list = []
      this.loading = true
      this.loading2 = false
      this.page = 1
      this.current_type_index = 0
      this.current_group_index = 0

      if (this.year_range[0] != null) {
        this.powerId = ''
      }
      console.log(this.year_range)
      this.get_index()
    }
  }
}
</script>
<style scoped>
.tab2{
  display: block;
  text-align: left;
  padding: 10px 40px 30px 0px;
}
.tab2 .el-tag,.tab2  .el-dropdown{
  vertical-align: middle;
}
.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}

.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
li{
  background: lightblue;
}
li div.border_bottom{
  border-bottom:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_top{
  border-top:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_right{
  border-right:  1px solid rgba(0, 0, 0, 0.08);
}
li div.border_left{
  border-left:  1px solid rgba(0, 0, 0, 0.08);
}
li div{
  /* vertical-align: middle; */
  /* display:table-cell; */
  /* border: 1px solid pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;

}
li div.left,li div.right{
  display: inline-block;
  width: 98px;
  vertical-align: top;
}
li .heng2{
  width: 200px;
}
#powers{
  font-size: 14px;
}
#powers  span.power_select{
  color:#50b7c1;
}
#powers  .power_select{
  color:#50b7c1;
  /* font-size: 20px; #50b7c1;}
.net_view .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color:#de8af8;border-color:#de8af8;}
.net_view .el-checkbox__input.is-checked + .el-checkbox__label{color:#de8af8;}
.net_view .el-checkbox.is-bordered.is-checked{border-color:#de8af8;}*/
}
/* #powers  span.name{
  color:#ffd04b;
  font-size: 20px;
} */
li .num{
  color: rgba(0, 0, 0, 0.32);
  font-size: 12px;
  display: inline-block;
  margin-left: 4px;
}

</style>
